import React from 'react';
import Particle from './Particle';

const ReadList = () => {
  return (
    <div className="readlist-section">
      <Particle />
      <ul className="readlist-section-ul" style={{ zIndex: 1 }}>
        <li>
          <a
            href="https://alexkondov.com/tao-of-react/"
            target="_blank"
            rel="noopener noreferrer"
          >
            👉 Tao of React - Software Design, Architecture & Best Practices
          </a>
        </li>
        <li>
          <a
            href="https://frontendmastery.com/posts/the-new-wave-of-react-state-management/"
            target="_blank"
            rel="noopener noreferrer"
          >
            👉 The new wave of React state management (Excellent read!)
          </a>
        </li>
        <li>
          <a
            href="https://alexsidorenko.com/blog/react-render-usememo/"
            target="_blank"
            rel="noopener noreferrer"
          >
            👉 A Visual Guide to React Rendering - useMemo
          </a>
        </li>
        <li>
          <a
            href="https://overreacted.io/react-as-a-ui-runtime/"
            target="_blank"
            rel="noopener noreferrer"
          >
            👉 React as a UI Runtime (By Dan Abramov from the React team)
          </a>
        </li>
        <li>
          <a
            href="https://react.dev/learn/you-might-not-need-an-effect"
            target="_blank"
            rel="noopener noreferrer"
          >
            👉 You Might Not Need an Effect (Official React docs)
          </a>
        </li>
        <li>
          <a
            href="https://overreacted.io/a-complete-guide-to-useeffect/"
            target="_blank"
            rel="noopener noreferrer"
          >
            👉 A Complete Guide to useEffect (By Dan Abramov)
          </a>
        </li>
        <li>
          <a
            href="https://thoughtspile.github.io/2021/11/15/unintentional-layout-effect/"
            target="_blank"
            rel="noopener noreferrer"
          >
            👉 useEffect sometimes fires before paint
          </a>
        </li>
        <li>
          <a
            href="https://overreacted.io/making-setinterval-declarative-with-react-hooks/"
            target="_blank"
            rel="noopener noreferrer"
          >
            👉 Making setInterval Declarative with React Hooks (By Dan Abramov)
          </a>
        </li>
        <li>
          <a
            href="https://blog.isquaredsoftware.com/2018/03/redux-not-dead-yet/"
            target="_blank"
            rel="noopener noreferrer"
          >
            👉 Redux - Not Dead Yet! (By Mark Erikson from the Redux team)
          </a>
        </li>
        <li>
          <a
            href="https://blog.isquaredsoftware.com/2021/01/context-redux-differences/"
            target="_blank"
            rel="noopener noreferrer"
          >
            👉 Why React Context is Not a 'State Management' Tool (By Mark
            Erikson)
          </a>
        </li>
        <li>
          <a
            href="https://vitejs.dev/guide/why.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            👉 Vite (Why Vite is so fast)
          </a>
        </li>
        <li>
          <a
            href="https://overreacted.io/writing-resilient-components/"
            target="_blank"
            rel="noopener noreferrer"
          >
            👉 Writing Resilient Components (By Dan Abramov from the React team)
          </a>
        </li>
        <li>
          <a
            href="https://github.com/mithi/react-philosophies"
            target="_blank"
            rel="noopener noreferrer"
          >
            👉 Things I think about when I write React code (GitHub repository)
          </a>
        </li>
        <li>
          <a
            href="https://blog.isquaredsoftware.com/2020/05/blogged-answers-a-mostly-complete-guide-to-react-rendering-behavior/"
            target="_blank"
            rel="noopener noreferrer"
          >
            👉 A (Mostly) Complete Guide to React Rendering Behavior (By Mark
            Erikson from the redux team)
          </a>
        </li>
        <li>
          <a
            href="https://alexsidorenko.com/blog/react-render-always-rerenders/"
            target="_blank"
            rel="noopener noreferrer"
          >
            👉 A Visual Guide to React Rendering (A multi-part series, check out
            the other ones)
          </a>
        </li>
        <li>
          <a
            href="https://levelup.gitconnected.com/how-exactly-does-react-handles-events-71e8b5e359f2"
            target="_blank"
            rel="noopener noreferrer"
          >
            👉 The React and React Native Event System Explained
          </a>
        </li>
        <li>
          <a
            href="https://gist.github.com/romain-trotard/76313af8170809970daa7ff9d87b0dd5"
            target="_blank"
            rel="noopener noreferrer"
          >
            👉 Under the hood of event listeners in React
          </a>
        </li>
        <li>
          <a
            href="https://github.com/pomber/didact"
            target="_blank"
            rel="noopener noreferrer"
          >
            👉 A DIY guide to build your own React
          </a>
        </li>
        <li>
          <a
            href="https://julesblom.com/writing/usesyncexternalstore"
            target="_blank"
            rel="noopener noreferrer"
          >
            👉 useSyncExternalStore First Look
          </a>
        </li>
        <li>
          <a
            href="https://the-guild.dev/blog/react-hooks-system"
            target="_blank"
            rel="noopener noreferrer"
          >
            👉 Under the hood of React's hooks system
          </a>
        </li>
        <li>
          <a
            href="https://overreacted.io/why-do-hooks-rely-on-call-order/"
            target="_blank"
            rel="noopener noreferrer"
          >
            👉 Why Do React Hooks Rely on Call Order? (By Dan Abramov)
          </a>
        </li>
        <li>
          <a
            href="https://thoughtspile.github.io/2021/05/17/everything-about-react-refs/"
            target="_blank"
            rel="noopener noreferrer"
          >
            👉 So you think you know everything about React refs
          </a>
        </li>
        <li>
          <a
            href="https://github.com/streamich/react-use"
            target="_blank"
            rel="noopener noreferrer"
          >
            👉 react-use: Reusable React Hook Library (GitHub repository)
          </a>
        </li>
        <li>
          <a
            href="https://github.com/react-hookz/web"
            target="_blank"
            rel="noopener noreferrer"
          >
            👉 react-hookz: React hooks done right (GitHub repository)
          </a>
        </li>
        <li>
          <a
            href="https://www.robinwieruch.de/react-libraries/"
            target="_blank"
            rel="noopener noreferrer"
          >
            👉 React Libraries for 2023 (Written in 2023, but will be up-to-date
            for years, as the ecosystem is relatively stable now)
          </a>
        </li>
        <li>
          <a
            href="https://www.joshwcomeau.com/css/styled-components/"
            target="_blank"
            rel="noopener noreferrer"
          >
            👉 Styled-components best practices (By the excellent writer and
            educator Josh W. Comeau)
          </a>
        </li>
        <li>
          <a
            href="https://css-tricks.com/a-thorough-analysis-of-css-in-js/"
            target="_blank"
            rel="noopener noreferrer"
          >
            👉 A Thorough Analysis of CSS-in-JS
          </a>
        </li>
        <li>
          <a
            href="https://tkdodo.eu/blog/practical-react-query"
            target="_blank"
            rel="noopener noreferrer"
          >
            👉 Practical React Query (A huge series from one of React Query's
            maintainers. Started in 2020, but is still going, and still very
            helpful)
          </a>
        </li>
        <li>
          <a
            href="https://tkdodo.eu/blog/react-query-meets-react-router"
            target="_blank"
            rel="noopener noreferrer"
          >
            👉 React Query meets React Router (From the same series as above)
          </a>
        </li>
        <li>
          <a
            href="https://www.benmvp.com/blog/picking-right-react-component-pattern/"
            target="_blank"
            rel="noopener noreferrer"
          >
            👉 Picking the right React component pattern
          </a>
        </li>
        <li>
          <a
            href="https://github.com/alan2207/bulletproof-react"
            target="_blank"
            rel="noopener noreferrer"
          >
            👉 Bulletproof-react: A simple, scalable, and powerful architecture
            for building production-ready React applications (GitHub repository)
          </a>
        </li>
      </ul>
    </div>
  );
};

export default ReadList;
