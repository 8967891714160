import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ProjectCard from './ProjectCards';
import Particle from '../Particle';
import StudentClub from '../../Assets/Projects/StudentClub1.png';
import ProShop from '../../Assets/Projects/ProShop.png';
import fastPizza from '../../Assets/Projects/fast-pizza2.png';
import WildOasis from '../../Assets/Projects/WildOasis.png';
import WordWise2 from '../../Assets/Projects/worldwise2.png';
import SMMMveliyildirim from '../../Assets/Projects/SMMMVeliyildirim.png';

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />

      <Container>
        <h1 className="project-heading">
          My Recent <strong className="yellow">Works </strong>
        </h1>
        <p style={{ color: 'white' }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: 'center', paddingBottom: '10px' }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={fastPizza}
              isBlog={false}
              title="Fast-React Pizza"
              description="It is a pizza ordering project where react-Router is used for remote state management and Redux toolkit for UI state management. "
              ghLink="https://github.com/cagatayiscann/fast-react-pizza"
              demoLink="https://catopizza.netlify.app/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={SMMMveliyildirim}
              isBlog={false}
              title="Financial Adviser Website"
              description="A professional looking website for a financial adviser. "
              demoLink="https://smmmveliyildirim.com//"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={WordWise2}
              isBlog={false}
              title="Worldwise"
              description="It is a journal app where you can choose a place on the map or get current location. Related informations are fetched about the place. react.js, Material-UI, and Firebase."
              ghLink="https://github.com/cagatayiscann/worldwise"
              demoLink="https://catowise.netlify.app/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={ProShop}
              isBlog={false}
              title="ProShop"
              description="A professional Looking e-commerce website where I used React, legacy Redux, axios and Django"
              ghLink="https://github.com/cagatayiscann/proshop"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={StudentClub}
              isBlog={false}
              title="Student Club"
              description="This is a student club project where if given some people have authority to create a club, accept and decline join requests, create an event. Every user can see clubs and their events. I used React, legacy Redux and Django for this project."
              ghLink="https://github.com/cagatayiscann/studentclub"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={WildOasis}
              isBlog={false}
              title="The Wild Oasis"
              description="This is a React and SupaBase project where we manage bookings of a hotel."
              ghLink="https://github.com/cagatayiscann/the-wild-oasis"
              demoLink="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ab_channel=RickAstley"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
